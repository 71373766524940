/* .App,
.App-header,
html {
  background-color: #282c34;
} */

.App {
  text-align: center;
  background-color: #282c34;
}

.logo {
  width: auto;
  height: 20px;
}

.title,
.body-text {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
  color: darkgrey;
  font-family: "Roboto", sans-serif;
}

.cardTitle {
  font-family: "Roboto", sans-serif;
  text-align: "left";
  font-weight: "bold";
}

.contextCard {
  max-width: 50rem;
  height: auto;
}

.col-image-transition {
  flex-grow: 1;
  transition: all 150ms ease-out;
}

.itemTitle {
  text-align: left;
  font-size: 20pt;
  font-weight: bold;
}

.projectItemDetails {
  text-align: left;
  font-size: 12pt;
  display: block;
}

.personItemDetails {
  text-align: left;
  font-size: 15pt;
  display: block;
  text-decoration: underline;
  font-weight: bold;
}

.itemDate {
  text-align: left;
  font-size: 10pt;
  display: block;
}

.itemInformation {
  text-align: left;
  font-size: 11pt;
  display: block;
}

html {
  /* padding-left: 15px;
  padding-right: 15px; */
  background-color: #282c34;
}

body {
  padding-top: 50px;
}

.projectImage {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 20px;
}

.project-thumbnail {
  position: relative;
  height: auto;
  min-width: 150px;
  max-width: 800px;
}

.project-thumbnail:hover .projectImage {
  opacity: 0.5;
}

.itemText {
  color: whitesmoke;
  font-size: 1rem;
  top: 50%;
  left: 50%;
  text-align: center;
}

#project-title {
  /* padding-bottom: 35px; */
  top: 30%;
  left: 50%;
  font-weight: bold;
}

#project-category {
  /* padding-top: 30px; */
  top: 60%;
  left: 50%;
}

.formLabel {
  color: whitesmoke;
}

#inlineMessageSuccess {
  color: green;
}
#inlineMessageSending {
  color: yellow;
}
#inlineMessageFail {
  color: red;
}

.PageNotFound {
  color: darkgrey;
  padding-top: 150px;
  font-size: 50pt;
}

.contactFooter {
  margin-top: 1rem;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
